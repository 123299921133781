export default defineComponent({
  data: function data() {
    return {
      planHeaders: ["Compare Plans", "Free", "Advanced", "Pro"],
      planDetails: [{
        type: "header",
        title: "Data Coverage:"
      }, {
        type: "feature",
        title: "Geographic coverage",
        info: "Subscribe to the tier of your choice to access TIKR's features & data for the relevant geography",
        free: "U.S.",
        plus: "Full global data",
        pro: "Full global data"
      }, {
        type: "feature",
        title: "Financial history",
        info: "Detailed income statement, balance sheet, cash flow statement, and ratios. Y = Year, Q = Quarters",
        // TODO: change when FMP released
        free: "5Y",
        plus: "10Y",
        pro: "10Y" // TODO: Change to 20 years when FMP released
      }, {
        type: "feature",
        title: "Charting",
        info: "Easily visualize any financial or valuation data. Build beautiful graphs to analyze trends over time!",
        free: "5Y",
        plus: "10Y",
        pro: "20Y"
      }, {
        type: "feature",
        title: "Screener",
        info: "Filter through the universe of stocks with 1,000+ metrics, including by country, industry, financials, ratios, and multiples",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "News",
        info: "Stay on top of the markets and your companies with high quality news powered by Reuters",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Company filings",
        info: "Access filings for companies globally as TIKR compiles filings from the SEC, SEDAR, and 15+ other sources",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Key valuation metrics",
        info: "Wall street stock price targets & valuation history for multiples including EV/Revenues, EV/EBITDA, P/E, P/FCF, P/BV, and more",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Transcripts",
        info: "Read transcripts for company earnings and conferences & view investor presentations",
        free: "90 day history",
        plus: "3 years",
        pro: "Full history"
      }, {
        type: "feature",
        title: "Forecasted analyst estimates",
        info: "Wall Street analyst estimates for revenue, EBITDA, EBIT, Net Income, EPS and Free Cash Flow	",
        free: "2 years",
        plus: "3 years",
        pro: "5 years"
      }, {
        type: "feature",
        title: "Ownership",
        info: "View who owns your target company's stock. Filter by investor type including hedge fund, insider, etc.",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "header",
        title: "Premium Tools & Data:"
      }, {
        type: "feature",
        title: "Detailed analyst estimates",
        info: "View detailed Wall Street forecasts for metrics including Gross Margin, FCF, net debt, CAPEX, ROE, ROA, and more",
        free: "checkFalse",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Investor guru portfolio holdings",
        info: "Monitor the stock holdings of the best institutional investors around the world. TIKR goes beyond just 13-F filings and also tracks international filings, shareholder reports, 13Ds, and more",
        free: "Top 40 funds",
        plus: "10,000+ funds",
        pro: "10,000+ funds"
      }, {
        type: "feature",
        title: "Premium metrics",
        info: "Premium metrics include EV/GP, P/NAV, P/NCAV, P/AFFO, P/FFO, AFFO, FFO, NAV, NCAV, and more",
        free: "checkFalse",
        plus: "checkFalse",
        pro: "checkTrue"
      },
      // {
      //   type: "feature",
      //   title: "Segment financials",
      //   info: "Access companies’ financials by reported business segment & geography",
      //   free: "checkFalse",
      //   plus: "checkFalse",
      //   pro: "checkTrue",
      // },
      {
        type: "feature",
        title: "Management guidance",
        info: "View forward management guidance for revenue, EBITDA, EPS, FCF and more as reported by companies",
        free: "checkFalse",
        plus: "checkFalse",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "# of saved watchlists",
        info: "Stay on top of your companies with our watchlist newsfeed that highlights upcoming events, company news, filings & transcripts",
        free: "Unlimited",
        plus: "Unlimited",
        pro: "Unlimited"
      }, {
        type: "feature",
        title: "Custom market newsfeeds",
        info: "Filter the news by hundreds of topics - industry, region, macro, company events (share buybacks, M&A, change of CEO, etc.)",
        free: "1",
        plus: "5",
        pro: "Unlimited"
      }, {
        type: "feature",
        title: "# of saved screens",
        info: "Save screens to automate your idea generation process.",
        free: "1",
        plus: "5",
        pro: "Unlimited"
      }, {
        type: "header",
        title: "Personalized User Settings:"
      }, {
        type: "feature",
        title: "Dark mode",
        info: "",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Business Owner Mode",
        info: "Hide all stock prices on TIKR to analyze businesses like an Owner would without any bias. After completing your analysis, turn prices back on to see how your valuation compares to the market's price!",
        free: "checkFalse",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "App language",
        info: "View TIKR in English, Japanese, Chinese, Korean, Spanish and more",
        free: "checkTrue",
        plus: "checkTrue",
        pro: "checkTrue"
      }, {
        type: "feature",
        title: "Support",
        info: "",
        free: "checkFalse",
        plus: "Standard",
        pro: "Priority"
      }, {
        type: "hidden",
        title: "Transcript PDFs & Audio",
        info: "View earnings and conference transcripts on the go",
        free: "checkFalse",
        pro: "checkTrue"
      }, {
        type: "hidden",
        title: "Custom Screener Formula",
        info: "Build custom screener formulas to filter using your own metrics",
        free: "checkFalse",
        pro: "checkTrue"
      }]
    };
  },
  computed: {
    dark: function dark() {
      return this.$vuetify.theme.dark;
    }
  }
});